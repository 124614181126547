@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
  width: 100%;
  height: 100%;
}

.loader {
  @apply w-full h-full flex items-center justify-center;
}